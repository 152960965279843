import React from 'react'
import { Modal } from '@jsluna/modal'
import { Body1 } from '@jsluna/typography'

const ImageThumbnail: React.FC<{ link: string }> = ({ link }) => {
    const [isOpen, setIsOpen] = React.useState(false)

    return (
      <Body1 className="r-image-thumbnail" id="r-image-thumbnail">
        <div
          role="button"
          tabIndex={0}
          onClick={() => setIsOpen(true)}
          onKeyDown={() => setIsOpen(true)}
        >
          <img
            src={link}
            alt=""
            aria-label="image-thumbnail"
            width="450"
            height="450"
          />
        </div>
        <Modal
          medium="true"
          handleClose={() => setIsOpen(false)}
          open={isOpen}
          headingId="info-modal"
        >
          <img src={link} alt="" />
        </Modal>
      </Body1>
    )
  }

export default ImageThumbnail
